import { createStore } from 'vuex'

export default createStore({
  state: {
    elevatorType: {
      value: "passenger_elevator",
      name: "Passenger"
    }
  },
  getters: {
  },
  mutations: {
    setElevatorType (state, elevatorType) {
      state.elevatorType = elevatorType
    }
  },
  actions: {
  },
  modules: {
  }
})
