<template>
  <div class="landingPageButtonParent">
    <div class="icon">
      <img :src="image" :alt="value" width="64" height="64">
    </div>
    <div class="landingPageButtonTexts">
      <h4>{{ elevatorType }} Elevator</h4>
      <p>Something small about passenger elevators</p>
    </div>
    <div class="button">
      <RoundedLiftMartButton @rounded-lift-mart-button-click="$emit('landingPageButtonClick')">Select</RoundedLiftMartButton>
    </div>
  </div>
</template>

<script>
import RoundedLiftMartButton from '@/components/RoundedLiftMartButton.vue'

export default {
  name: 'LandingPageOptionButton',
  components: {
    RoundedLiftMartButton
  },
  props: {
    elevatorType: String,
    image: String,
    value: String
  }
}
</script>

<style scoped>
.landingPageButtonParent {
  min-height: 14px;
  display: grid;
  grid-template-columns: 64px 1fr 140px;
  grid-template-rows: 80px;
  gap: 0px 12px;
  grid-auto-flow: row;
  grid-template-areas:
    "icon landingPageButtonTexts button";
  background-color: #f1f1f1;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
  margin-bottom: 12px;
  transition: background-color 100ms ease 0s;
}

.landingPageButtonParent:hover {
  background-color: #FFFFFF;
}

.landingPageButtonTexts {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "."
    ".";
  grid-area: landingPageButtonTexts;
  align-items: center;
  align-content: center;
  align-self: center; 
}

.landingPageButtonTexts h4 {
  margin: 0px;
  font-size: 20px;
}

.landingPageButtonTexts p {
  margin: 0px;
  font-size: 14px;
}

.button {
  align-self: center;
  grid-area: button;
}

.icon {
  justify-self: center;
  align-self: center;
  grid-area: icon;
}
</style>