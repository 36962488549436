<template>
  <router-view/>
</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  window.handleUnitySceneLoaded = function() {
    console.log("Unity scene has loaded.");
  };
})
</script>

<style type="text/css">
* {
 font-family: Inter;
}

html, body, #app{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #f1f1f1;
}
</style>