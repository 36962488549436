<template>
    <a href="#" class="RoundedLiftMartButton" @click="$emit('roundedLiftMartButtonClick')"><slot></slot></a>
</template>

<script>
export default {
  name: 'RoundedLiftMartButton'
}
</script>

<style scoped>
.RoundedLiftMartButton {
  box-shadow: 0px 4px 3px 0px #c9c9c9;
  background-color:#ffffff;
  border-radius:42px;
  display:inline-block;
  cursor:pointer;
  color:#2a2c86;
  font-family:Arial;
  font-size:14px;
  padding:8px 48px;
  text-decoration:none;
}

.RoundedLiftMartButton:hover {
  background-color:#2a2c86;
  color:#ffffff;
}

.RoundedLiftMartButton:active {
  position:relative;
  top:1px;
}
</style>