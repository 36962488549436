<template>
  <div class="container">
    <div class="leftside">
      <h1>Welcome to the Lift Mart Elevator Designer!</h1>
      <h4>Your ultimate destination to customize, visualize, and create the perfect elevator for your projects</h4>
      <LandingPageOptionButton
        v-for="(type, index) in types"
        v-bind:key="index"
        :elevatorType="type.name"
        :value="type.value"
        :image="type.image"
        @landing-page-button-click="handleLandingPageOptionClick(type)"/>
    </div>
    <div class="rightside">
      <img src="@/assets/lift_animation_placeholder.png" alt="Animated Lift">
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LandingPageOptionButton from '@/components/LandingPageOptionButton.vue'

const router = useRouter()

const types = ref([
  {
    value: "passenger_elevator",
    image: "/elevator_types/passenger.png",
    name: "Passenger"
  },
  {
    value: "panoramic_elevator",
    image: "/elevator_types/panoramic.png",
    name: "Panoramic"
  },
  {
    value: "service_elevator",
    image: "/elevator_types/service.png",
    name: "Service"
  },
  {
    value: "car_elevator",
    image: "/elevator_types/car.png",
    name: "Car"
  }
])

const handleLandingPageOptionClick = (type) => {
  router.push({ path: '/design', query: { elevator_type: type.value } })
}
</script>

<style scoped>
.container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "leftside rightside";
}

.leftside {
  padding-left: 32px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 64px 100px 100px 100px 100px 24px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "."
    "."
    "."
    "."
    "."
    "."
    ".";
  align-content: center; 
  align-items: center; 
  grid-area: leftside;
}

.rightside {
  grid-area: rightside;
  justify-self: center; 
  align-self: center;
}

.leftside a {
  color: #717171;
  font-size: 12px;
}

.rightside img {
  height: 100%;
  display: block;
}
</style>